<template>
  <vs-card class="emailCard">
    <vs-form>
      <vs-label class="label">Subject Line</vs-label>
      <vs-input
        v-validate="'required|min:3|max:200'"
        class="col-sm-3"
        data-vv-validate-on="blur"
        name="subject"
        v-model="pages.subject"
      ></vs-input>
      <span class="error">{{ errors.first("subject") }}</span>
    </vs-form>
    <div class="editEmail">
      <vs-label class="label">Email Content</vs-label>
    </div>
    <vs-label class="label">Available Linked Content</vs-label>
    <div class="linkedContent">
      <ul>
        <li class="keywords">
          <span v-pre>username</span>
        </li>
        <li class="keywords">
          <span v-pre>projectName</span>
        </li>
        <li
          class="keywords"
          v-if="
            pages.slug === 'forgot-password' ||
            pages.slug === 'reset-password' ||
            pages.slug === 'verification-email'
          "
        >
          <span v-pre>link</span>
        </li>
        <li class="keywords" v-if="pages.slug === 'new-user-created'">
          <span v-pre>password</span>
        </li>

        <li class="keywords" v-if="pages.slug === 'email-to-seller'">
          <span v-pre>senderName</span>
        </li>
        <li class="keywords" v-if="pages.slug === 'email-to-seller'">
          <span v-pre>senderEmail</span>
        </li>
        <li class="keywords" v-if="pages.slug === 'email-to-seller'">
          <span v-pre>senderPhone</span>
        </li>
        <li class="keywords" v-if="pages.slug === 'email-to-seller'">
          <span v-pre>message</span>
        </li>
      </ul>
    </div>
    <div class="editEmail">
      <vs-label class="label">Body Content</vs-label>
      <quillEditor :pages="pages"></quillEditor>
    </div>
    <div slot="footer" class="footer">
      <vs-row vs-justify="flex-end">
        <vs-button color="success" @click="saveEmailDetails" align="left"
          >Save</vs-button
        >
        <vs-button
          color="danger"
          style="margin-left: 5px"
          @click="$router.replace('/admin/email-template')"
          >Cancel</vs-button
        >
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import quillEditor from "../../Editor";
import moment from "moment";

export default {
  name: "emailViewEditDetail",
  components: {
    quillEditor,
  },
  data: () => ({
    pages: {
      content: "",
      subject: "",
    },
  }),
  methods: {
    ...mapActions("email", ["fetchEmailDetails", "updateEmailDetails"]),
    getEmailDetail(id) {
      let self = this;
      this.fetchEmailDetails(id).then((res) => {
        this.pages.content = res.data.data.content;
        this.pages.subject = res.data.data.subject;
        this.pages = res.data.data;
      });
    },
    saveEmailDetails() {
      this.$validator.validateAll().then((success) => {
        if (!success) {
          return false;
        }
        let id = this.$route.params.id;
        let info = this.pages;
        this.updateEmailDetails({ id, info }).then((res) => {
          this.$vs.notify({
            subject: "Update Page Content",
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
        });
      });
    },
  },
  created() {
    let pageId = this.$route.params.id;
    this.getEmailDetail(pageId);
  },
};
</script>
<style>
.footer {
  padding-top: 40px;
  clear: both;
}
.error {
  color: red;
}
.ql-editor {
  min-height: 200px;
}
ul {
  list-style: none;
  margin-left: 0px;
}
.keywords {
  display: inline-block;
  border: 1px solid dimgrey;
  margin-right: 3px;
  padding: 5px;
  color: white;
  background-color: #3dc9b3;
}
.label {
  font-size: small;
  color: black;
}
.editEmail {
  padding-top: 20px;
  padding-bottom: 20px;
}
.emailCard {
  min-height: 650px;
  padding-left: 20px;
}
</style>
